import { createReducer, on } from '@ngrx/store';
import { Partner } from '@hub-services/partner';
import { User } from '@hub-services/user.service';
import * as SessionActions from './session.actions';
import { PartnerType } from '../../admin/admin-partners/partner-type.enum';

export const featureKey = 'session';

export interface HubSessionState {
  data: {
    partner_id: number;
    partner_type: string;
    user_id: number;
  },
  included: {
    partners: Partial<Partner>[];
    users: User[];
  },
  partner_id: number;
  partner_type: PartnerType;
  user_id: number;
}

export const initialState: HubSessionState = {
  data: null,
  included: null,
  partner_id: null,
  partner_type: null,
  user_id: null,
};

export const reducer = createReducer(
  initialState,

  on(SessionActions.setSessionData, (state, action) => ({
    ...state,
    ...action.data
  })),

  on(SessionActions.updateSessionUserInStore, (state, action) => ({
    ...state,
    included: {
      ...state.included,
      users: [{
        ...state.included.users[0],
        ...action.user
      }]
    }
  })),

);
