import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSession from './session.reducer';

export const selectSessionState = createFeatureSelector<fromSession.HubSessionState>(
  fromSession.featureKey
);

export const getSession = createSelector(selectSessionState, (state) => state);
export const getPartnerId = createSelector(selectSessionState, (state) => state.partner_id);
export const getSessionData = createSelector(selectSessionState, (state) => state.data);
export const getCurrentUser = createSelector(selectSessionState, (state) => state.included?.users[0]);
export const getPartnerIdAndType = createSelector(selectSessionState, (state) => ({
  id: state.partner_id,
  type: state.partner_type,
}))
