import { Partner } from './partner';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from './api';
import { RoleType } from '../admin/role-type.enum';

export interface User {
  id?: number;
  avatar_large_url?: string;
  avatar_thumb_url?: string;
  role: RoleType;
  scope: string;
  permissions?: Array<string>;
  is_superuser?: boolean;
  accepted_terms: boolean;
  name?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  isCommunityParticipant?: boolean;
}

@Injectable({ providedIn: 'root' })
export class UserService {
  path: string = '/api';
  constructor(private apiService: ApiService) { }

  private setUser(): Observable<User> {
    return this.apiService.get(`${this.path}/user`).pipe(
      catchError(() => of({})),
      map((response: any) => {
        const user = response;
        if (user) {
          if (user?.role !== RoleType.ADMIN && !user.scope) {
            return user;
          } else {
            localStorage.setItem('currentUser', JSON.stringify(user));
            return user;
          }
        }
      }),
    );
  }

  public getUser(cache: boolean = true) {
    const storedUser = localStorage.getItem('currentUser');
    if (!cache || !storedUser) {
      return this.setUser();
    } else {
      return of(JSON.parse(storedUser));
    }
  }

  public getUsers() {
    return this.apiService.get(`${this.path}/users`);
  }

  public getUserById(id: number) {
    return this.apiService.get(`${this.path}/users/${id}/profile`);
  }

  public getCurrentUserProfile() {
    return this.apiService.get(`${this.path}/v2/users/profile`);
  }

  public deleteUser(id: number, partnerId: any) {
    return this.apiService.delete(`${this.path}/airlines/${partnerId}/users/${id}`);
  }

  public createUser(data: FormData) {
    return this.apiService.postForm(`${this.path}/users`, data);
  }

  public updateUserById(id: number, args: any) {
    return this.apiService.put(`${this.path}/users/${id}/profile`, { data: args });
  }

  public updateProfile(profileData: FormData) {
    return this.apiService
      .putForm(`${this.path}/v2/users/profile`, profileData);
  }

  public updateUser(): Observable<User> {
    return this.setUser();
  }

  public getAirlineUsers(airlineId: string) {
    return this.apiService.get(`${this.path}/airlines/${airlineId}/users`);
  }

  public getAdminUsers() {
    return this.apiService.get(`${this.path}/admins`).pipe(catchError(() => of([])));
  }

  getPermissionDisplayChangePartner(user: User): boolean {
    return user.role === RoleType.ADMIN;
  }

  getPermissionDisplayFooterUasLinks(user: User): boolean {
    return user.scope === 'airline';
  }

  getPermissionIsAirline(user: User): boolean {
    return user.scope === 'airline';
  }

  getPermissionDisplayGalleryLinks(user: User): boolean {
    return user.scope === 'airline' && user.role !== RoleType.OBSERVER;
  }

  getPermissionDisplayListsLinks(user: User): boolean {
    return user.scope === 'airline' && user.permissions?.includes('manage_targeting');
  }

  getPermissionManageLists(user: User): boolean {
    return user.permissions?.includes('manage_lists');
  }

  getPermissionDisplaySettingsLinks(user: User): boolean {
    return (
      user.scope === 'airline' &&
      [RoleType.ADMIN, RoleType.SUPERUSER, RoleType.COMMUNITY_PARTICIPANT].includes(user.role)
    );
  }

  getPermissionUaTargeting(user: User): boolean {
    return user.permissions?.includes('manage_targeting');
  }

  getPermissionUaPublishing(user: User): boolean {
    return user.scope === 'airline' && (user.role === RoleType.ADMIN || user.role === RoleType.SUPERUSER);
  }

  getPermissionShowDisable(user: User): boolean {
    return user.permissions?.includes('disable_upas');
  }

  getPermissionShowAirlineChannels(user: User): boolean {
    return user.scope === 'airline' && user.permissions?.includes('view_airline_channels');
  }

  getPermissionShowAirlineUpas(user: User): boolean {
    return user?.permissions?.includes('view_airline_upas') || [RoleType.COLLABORATOR, RoleType.COMMUNITY_PARTICIPANT].includes(user?.role);
  }

  getPermissionShowAirlineUtas(user: User): boolean {
    return user.permissions?.includes('view_airline_utas');
  }

  getPermissionShowAirlineAmenities(user: User): boolean {
    return user.permissions?.includes('view_airline_amenities');
  }

  getPermissionCommunityParticipant(user: User): boolean {
    return user.permissions?.includes('community_participant');
  }

  getPermissionShowUtasV1(user: User, airline: Partner) {
    return (
      user.scope === 'airline' &&
      user.role === RoleType.ADMIN &&
      user.permissions?.includes('view_airline_utas') &&
      airline.uta_source === 'hub'
    );
  }

  setFullstoryUser(user: User) {
    window['FS'].identify(user.id, {
      displayName: `${user.first_name} ${user.last_name}`.trim(),
      email: user.email,
    });
  }

  // Set new value for avatar_thumb_url in localStorage for currentUser
  updateAvatarInStorage(avatar_thumb_url: string) {
    localStorage.setItem('currentUser', JSON.stringify({
      ...JSON.parse(localStorage.getItem('currentUser')),
      avatar_thumb_url,
    }));
  }
}
