import { createReducer, on } from '@ngrx/store';
import * as MediaActiions from './media.actions';
import { MediaFile } from '../../gallery/photos/media-file.model';

export const featureKey = 'media';

export interface HubMediaState {
  photos: MediaFile[];
  videos: any[];
  tours: any[];
  infographics: MediaFile[];
  marketing_graphics: MediaFile[];
}

export const initialState: HubMediaState = {
  photos: [],
  videos: [],
  tours: [],
  infographics: [],
  marketing_graphics: [],
};

export const reducer = createReducer(
  initialState,

  on(MediaActiions.setMediaData, (state, action) => ({
    ...state,
    ...action.data
  })),

  on(MediaActiions.resetMediaData, () => ({
    ...initialState
  })),

  on(MediaActiions.addNewMediaFileInStore, (state, action) => ({
    ...state,
    [action.item.media_type + 's']: [
      ...state[action.item.media_type + 's'],
      action.item,
    ]
  }))
);
