import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isObjInArray',
})
export class IsObjInArrayPipe implements PipeTransform {
  transform(inputObj: any, targetArr: any[], prop: string): boolean {
    return !!targetArr.find(item => item && item[prop] === inputObj[prop]);
  }
}
