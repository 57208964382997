import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isNotEmptyArray'
})
export class IsNotEmptyArrayPipe implements PipeTransform {

  transform(items: string[]): boolean {
    return items?.length > 0;
  }

}
