import { Injectable } from '@angular/core';

import { environment } from '@hub-env/environment';

@Injectable({
  providedIn: 'root'
})
export class HubFlightSearchGuard  {
  canActivate(): boolean {
    return !!environment.featureFlag?.flightSearch || !!environment.featureFlag?.findAFlight;
  }

}
