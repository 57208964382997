import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPartner from './selected-partner.reducer';

export const selectPartnerState = createFeatureSelector<fromPartner.HubPartnerState>(
  fromPartner.featureKey
);

export const getSelectedPartner = createSelector(selectPartnerState, state => state.partner);

export const getPartnerBrands = createSelector(getSelectedPartner, partner => partner.brands);

export const getSubfleets = createSelector(selectPartnerState, state => state.subfleets);

export const getUsers = createSelector(selectPartnerState, state => state.users);

export const getUserById = (id: number) => createSelector(selectPartnerState, state => state.users?.find(user => user.id === id));

export const getUsersAndAdmins = createSelector(selectPartnerState, state => ({
  users: state.users,
  admins: state.admins,
}))

