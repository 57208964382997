import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keywordFilter'
})
export class KeywordFilterPipe implements PipeTransform {

  transform(collection: any[], prop: string, keyword: string): any[] {

    if (!keyword?.length) {
      return collection;
    }

    const kw = keyword.toLowerCase();

    return collection.filter(item => item[prop]?.toLowerCase().includes(kw));

  }

}
