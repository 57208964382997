import { Partner } from '@hub-services/partner';
import { createReducer, on } from '@ngrx/store';
import { PartnerStatusFilter } from '../../admin/admin-partners/partner-status-filter.enum';
import { PartnerType } from '../../admin/admin-partners/partner-type.enum';
import * as PartnersListActions from './partners-list.actions';

export const featureKey = 'partnersList';

export interface HubPartnersListState {
  partners: Partner[];
  partnerType: PartnerType,
  status: PartnerStatusFilter,
}

export const initialState: HubPartnersListState = {
  partners: null,
  partnerType: null,
  status: PartnerStatusFilter.ACTIVE,
};

export const reducer = createReducer(
  initialState,

  on(PartnersListActions.setPartners, (state, action) => ({
    ...state,
    partners: action.partners,
  })),
  on(PartnersListActions.setStatus, (state, action) => ({
    ...state,
    status: action.status,
  })),
  on(PartnersListActions.setPartnerType, (state, action) => ({
    ...state,
    partnerType: action.partnerType,
  })),
  on(PartnersListActions.updatePartnerInStore, (state, action) => {
    const tmpState = JSON.parse(JSON.stringify(state));
    const idx = tmpState.partners.findIndex(p => p.id === action.partner.id);
    tmpState.partners[idx] = action.partner;

    return {
      ...tmpState
    }
  }),
  on(PartnersListActions.resetPartners, state => ({
    ...state,
    ...initialState,
  })),

);
