import { createAction, props } from '@ngrx/store';
import { FilterProperty } from './filters.reducer';

export const setFilterProp = createAction(
  '[Filters] Set filter property',
  props<{ name: FilterProperty, value: any }>()
);

export const resetFilters = createAction(
  '[Filters] Reset filters',
);

export const updateQueryParams = createAction(
  '[Filters] Update query params'
);

export const setBrandIds = createAction(
  '[Filters] Set brand ids',
  props<{ ids: any[] }>()
);

export const setUpaIds = createAction(
  '[Filters] Set UPA ids',
  props<{ ids: any[] }>()
);

export const setCabinIds = createAction(
  '[Filters] Set cabin ids',
  props<{ ids: any[] }>()
);

export const setAirlineIds = createAction(
  '[Filters] Set airline ids',
  props<{ ids: any[] }>()
);