import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as SavedViewsActions from './saved-views.actions';
import * as UaConfigSelectors from '@hub-store/ua-config/ua-config.selectors';
import * as FiltersSelectors from '@hub-store/filters/filters.selectors';
import { SavedViewsService } from "@hub-services/saved-views.service";
import { Store } from "@ngrx/store";
import { concatLatestFrom } from "@ngrx/operators";

@Injectable()
export class SavedViewsEffects {

  addEmptyView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SavedViewsActions.addEmptyView),
      concatLatestFrom(() => [
        this.store.select(FiltersSelectors.selectFiltersState),
        this.store.select(UaConfigSelectors.getStatus)
      ]),
      switchMap(([action, state, uaStatus]) => of(SavedViewsActions.addEmptyViewInStore({
        savedView: {
          attribute_type: action.attribute_type,
          state: uaStatus,
          name: '',
          is_new: true,
          filters: this.savedViewsService.getFilterValues(state)
        }
      }))
      )
    );
  });

  saveNewViewInApi$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SavedViewsActions.saveNewViewInApi),
      concatMap((action) => this.savedViewsService
        .addView({
          ...action.savedView,
        })
        .pipe(
          switchMap(savedView => of(SavedViewsActions.saveNewViewInStore({ savedView }))
          )
        )
      )
    );
  });

  updateViewInApi$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SavedViewsActions.updateViewInApi),
      concatMap(action => this.savedViewsService
        .updateView(action.savedView)
        .pipe(
          switchMap(savedView => of(SavedViewsActions.updateViewInStore({ savedView }))
          )
        )
      )
    );
  });

  updateOrderInApi$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SavedViewsActions.updateOrderInApi),
      concatMap(action => this.savedViewsService
        .updateOrder(action.items)
        .pipe(
          switchMap(items => of(SavedViewsActions.updateOrderInStore({ items }))
          )
        )
      )
    );
  });

  deleteViewInApi$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SavedViewsActions.deleteViewInApi),
      concatMap(action => this.savedViewsService
        .deleteView(action.id)
        .pipe(
          switchMap(data => of(SavedViewsActions.deleteViewInStore({ id: action.id }))
          )
        )
      )
    );
  });

  getViewsByUaType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SavedViewsActions.getViewsByUaType),
      concatMap(action => this.savedViewsService
        .getViews(action.uaType)
        .pipe(
          switchMap(items => of(SavedViewsActions.setViewsInStore({ items }))
          )
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private savedViewsService: SavedViewsService,
  ) { }

}
