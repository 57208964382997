import { Program } from '@hub-services/programs.service';
import { createReducer, on } from '@ngrx/store';
import { UpasByStatusCount } from '../../upas/upas-by-status-count.model';
import * as UaConfigActions from './ua-config.actions';
import { UaInspectVars } from './ua-inspect-vars.model';

export const featureKey = 'uaConfig';

const defaultLanguageCode = 'en';

export interface HubUaConfigState {
  languageCode: string;
  uaInspectVars: UaInspectVars;
  uaType: string;
  programs: Program[];
  airlines: any[];
  status: string;
  countByCategory: { [key: string]: number };
  countByStatus: UpasByStatusCount[];
}

export const initialState: HubUaConfigState = {
  languageCode: defaultLanguageCode,
  uaInspectVars: { index: -1, length: 0, uas: [] },
  uaType: '',
  programs: [],
  airlines: [],
  status: 'draft',
  countByCategory: {},
  countByStatus: [],
};

export const reducer = createReducer(
  initialState,

  on(UaConfigActions.setLanguageCode, (state, action) => ({
    ...state,
    languageCode: action.languageCode?.length ? action.languageCode : defaultLanguageCode,
  })),

  on(UaConfigActions.setDefaultLanguageCode, (state) => ({
    ...state,
    languageCode: defaultLanguageCode,
  })),

  on(UaConfigActions.setUaInspectVars, (state, action) => ({
    ...state,
    uaInspectVars: {
      ...action.data,
    },
  })),

  on(UaConfigActions.resetUaInspectVars, (state) => ({
    ...state,
    uaInspectVars: {
      ...initialState.uaInspectVars,
    },
  })),

  on(UaConfigActions.setUaType, (state, action) => ({
    ...state,
    uaType: action.uaType,
  })),

  on(UaConfigActions.setPrograms, (state, action) => ({
    ...state,
    programs: [...action.programs],
  })),

  on(UaConfigActions.resetPrograms, (state) => ({
    ...state,
    programs: [],
  })),

  on(UaConfigActions.setAirlines, (state, action) => ({
    ...state,
    airlines: [...action.airlines],
  })),

  on(UaConfigActions.resetAirlines, (state) => ({
    ...state,
    airlines: [],
  })),

  on(UaConfigActions.setStatus, (state, action) => ({
    ...state,
    status: action.status,
  })),

  on(UaConfigActions.resetStatus, (state) => ({
    ...state,
    status: initialState.status,
  })),

  on(UaConfigActions.resetUaConfig, () => ({
    ...initialState,
  })),
  on(UaConfigActions.setCategoryCount, (state, action) => ({
    ...state,
    countByCategory: { ...action.stats },
  })),
  on(UaConfigActions.setUpaStatusCount, (state, action) => ({
    ...state,
    countByStatus: [...action.stats],
  }))
);
