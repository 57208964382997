import { FlightSearch } from '@hub-common/flight-search/flight-search';
import { createReducer, on } from '@ngrx/store';
import * as FiltersActions from './filters.actions';

export const featureKey = 'filters';

export enum FilterProperty {
  viewFilter = 'viewFilter',
  groupByAirline = 'groupByAirline',
  groupByCategory = 'groupByCategory',
  filteredAirlineIds = 'filteredAirlineIds',
  filteredCategoryIds = 'filteredCategoryIds',
  filteredCabinIds = 'filteredCabinIds',
  filteredCabinCodes = 'filteredCabinCodes',
  filteredBrandIds = 'filteredBrandIds',
  filteredUpaIds = 'filteredUpaIds',
  filteredSubfleetIds = 'filteredSubfleetIds',
  filteredUpaSourceId = 'filteredUpaSourceId',
  flightSearch = 'flightSearch',
  viewId = 'viewId',
}

export interface HubFiltersState {
  viewFilter: string;
  groupByAirline: boolean;
  groupByCategory: boolean;
  filteredAirlineIds: number[];
  filteredCategoryIds: string[];
  filteredCabinIds: number[];
  filteredCabinCodes: string[];
  filteredBrandIds: number[];
  filteredUpaIds: number[];
  filteredSubfleetIds: number[];
  filteredUpaSourceId: string;
  flightSearch: FlightSearch;
  viewId: string | number;
}

export const initialState: HubFiltersState = {
  viewFilter: 'default',
  groupByAirline: null,
  groupByCategory: true,
  filteredAirlineIds: [],
  filteredCategoryIds: [],
  filteredCabinIds: [],
  filteredCabinCodes: [],
  filteredBrandIds: [],
  filteredUpaIds: [],
  filteredSubfleetIds: [],
  filteredUpaSourceId: null,
  flightSearch: {},
  viewId: '',
};

export const reducer = createReducer(
  initialState,

  on(FiltersActions.setFilterProp, (state, action) => ({
    ...state,
    [action.name]: action.value,
  })),

  on(FiltersActions.resetFilters, state => {
    return {
      ...state,
      ...initialState,
      viewFilter: state.viewFilter === 'flight' ? 'flight' : 'default',
      flightSearch: state.viewFilter === 'flight' ? state.flightSearch : {}
    }
  }),

);
