import { Partner } from '@hub-services/partner';
import { createAction, props } from '@ngrx/store';
import { PartnerStatusFilter } from '../../admin/admin-partners/partner-status-filter.enum';
import { PartnerType } from '../../admin/admin-partners/partner-type.enum';

export const setPartners = createAction(
  '[Partners List] Set partners',
  props<{ partners: Partner[] }>()
);

export const updatePartnerInStore = createAction(
  '[Partners List] Update partner in store',
  props<{ partner: Partner }>()
);

export const setStatus = createAction(
  '[Partners List] Set status',
  props<{ status: PartnerStatusFilter }>()
);

export const setPartnerType = createAction(
  '[Partners List] Set type',
  props<{ partnerType: PartnerType }>()
);

export const resetPartners = createAction(
  '[Partners List] Reset partners',
);
