import { createReducer, on } from '@ngrx/store';
import * as HelpActions from './help.actions';

export const featureKey = 'help';

export interface HubHelpState {
  helpVisible: boolean
}

export const initialState: HubHelpState = {
  helpVisible: false
};

export const reducer = createReducer(
  initialState,

  on(HelpActions.setHelpVisible, (state, action) => ({
    ...state,
    helpVisible: action.helpVisible
  })),

);
