import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'areUaFiltersApplied'
})
export class AreUaFiltersAppliedPipe implements PipeTransform {

  transform(
    filteredCabinIds: number[],
    filteredBrandIds: any[],
    filteredCategoryIds: any[],
    filteredSubfleetIds: any[],
    filteredUpaIds: any[],
    filteredUpaSourceId: string): boolean {
    return (
      filteredCabinIds.length > 0 ||
      filteredBrandIds.length > 0 ||
      filteredCategoryIds.length > 0 ||
      filteredSubfleetIds.length > 0 ||
      filteredUpaIds.length > 0 ||
      filteredUpaSourceId?.length > 0
    );
  }

}
