import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api';
import { MediaFile } from '../gallery/photos/media-file.model';
import { MediaType } from '../uas/editing/media/media-type.enum';
import { Observable, of } from 'rxjs';
import * as slug from 'slug';
import { HttpParams } from '@angular/common/http';
import { FileDimentions } from '../gallery/photos/file-dimentions.model';

@Injectable({ providedIn: 'root' })
export class MediaFileService {
  path: string = '/api';
  constructor(private apiService: ApiService) { }

  getMediaFiles(partnerId: number, mediaType: MediaType): Observable<MediaFile[]> {
    return this.apiService.get(`${this.path}/v2/airlines/${partnerId}/${mediaType}s`).pipe(catchError(() => of([])));
  }

  updateMediaFile(mediaFile: Partial<MediaFile>, mediaType: MediaType) {
    return this.apiService.put(`${this.path}/${mediaType}s/${mediaFile.id}`, {
      data: {
        name: mediaFile.name,
        upa_category_ids: mediaFile.upa_category_ids,
        subfleet_ids: mediaFile.subfleet_ids,
        generic_cabin_ids: mediaFile.generic_cabin_ids,
        airport_code: mediaFile.airport_code,
        alt: mediaFile.alt
      }
    });
  }

  deleteMediaFile(photoId: number, partnerId: number) {
    return this.apiService.delete(`${this.path}/v2/airlines/${partnerId}/photos/${photoId}`);
  }

  uploadMediaFile(partnerId: number, file: any, dimentions: FileDimentions, mediaType: MediaType) {

    const formData = new FormData();
    formData.append('photo[file]', file, this.sanitizeFileName(file.name));
    formData.append('photo[width]', dimentions.width.toString());
    formData.append('photo[height]', dimentions.height.toString());
    formData.append('photo[media_type]', mediaType);

    return this.apiService
      .postForm(`${this.path}/v2/airlines/${partnerId}/photos`, formData);
  }

  uploadMediaFileReplace(partnerId: number, fileId: number, file: any, dimentions: FileDimentions, mediaType: MediaType) {
    const formData = new FormData();
    formData.append('photo[file]', file, this.sanitizeFileName(file.name));
    formData.append('photo[width]', dimentions.width.toString());
    formData.append('photo[height]', dimentions.height.toString());
    formData.append('photo[media_type]', mediaType);

    return this.apiService
      .putForm(`${this.path}/v2/airlines/${partnerId}/photos/${fileId}`, formData);
  }

  getMediaFileUploads(partnerId: number, mediaType: MediaType) {
    return this.apiService.get(`${this.path}/airlines/${partnerId}/photo_uploads?media_type=${mediaType}`);
  }

  getMediaFileUploadById(partnerId: number, id: number) {
    return this.apiService.get(`${this.path}/v2/airlines/${partnerId}/photos/${id}`);
  }

  getMediaFileById(id: number, mediaType: MediaType): Observable<MediaFile> {
    return this.apiService.get(`${this.path}/photos/${id}`);
  }

  deleteMediaFileUpload(id: number, mediaType: MediaType) {
    return this.apiService.delete(`${this.path}/photo_uploads/${id}?media_type=${mediaType}`);
  }

  /**
   * TRansform file names into lower-case ASCII-chars only
   */
  sanitizeFileName(name: string): string {
    const nameTokens = name.split('.');

    // use last element as file extension
    const ext = nameTokens.pop();

    return slug(nameTokens.join('').replace(/\_/g, '-')) + '.' + ext;
  }
}
