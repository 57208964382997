import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromMessages from './messages.reducer';

export const selectMessagesState = createFeatureSelector<fromMessages.HubMessagesState>(
  fromMessages.featureKey
);

export const getAppMessages = createSelector(selectMessagesState, state => state.messages);

export const getAppSnackbar = createSelector(selectMessagesState, state => state.snackbar);

