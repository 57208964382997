import { Brand } from '@hub-services/interfaces/brand';
import { Partner } from '@hub-services/partner';
import { Subfleet } from '@hub-services/subfleets.service';
import { User } from '@hub-services/user.service';
import { createAction, props } from '@ngrx/store';
import { PartnerType } from '../../admin/admin-partners/partner-type.enum';

export const getPartnerFromBackend = createAction(
  '[Partner] Get partner by id from backend',
  props<{ id: number, partnerType: PartnerType }>()
);

export const setPartner = createAction(
  '[Partner] Set partner',
  props<{ partner: Partner }>()
);

export const setPartnerBrands = createAction(
  '[Partner] Set partner brands',
  props<{ brands: Brand[] }>()
);

export const deleteBrand = createAction(
  '[Partner] Delete brand',
  props<{ id: number }>()
);

export const getPartnerBrands = createAction(
  '[Partner] Get partner brands',
);

export const createBrands = createAction(
  '[Partner] Create brands',
  props<{ brands: Brand[] }>()
);

export const setPartnerBrandsInStore = createAction(
  '[Partner] Set partner brands in store',
  props<{ brands: Brand[] }>()
);

export const createUpdateBrandsInBackend = createAction(
  '[Partner] Create/Update brands in backend',
  props<{ brands: Brand[] }>()
);

export const setSubfeeets = createAction(
  '[Partner] Set subfleets',
  props<{ subfleets: Subfleet[] }>()
);

export const setUsersAndAdmins = createAction(
  '[Partner] Set users and admins',
  props<{ users: any[], admins: any[] }>()
);

export const resetPartnerState = createAction(
  '[Partner] Reset partner state',
);

export const createUserInBackend = createAction(
  '[Partner] Create user in backend',
  props<{ formData: FormData }>()
);

export const createUserInStore = createAction(
  '[Partner] Create user in store',
  props<{ user: User }>()
);

export const updateUserInBackend = createAction(
  '[Partner] Update user in backend',
  props<{ user: User, avatarChanged: boolean }>()
);

export const updateUserInStore = createAction(
  '[Partner] Update user in store',
  props<{ user: User }>()
);

export const updateProfilePicture = createAction(
  '[Partner] Update profile picture',
  props<{ user: User, formData: FormData }>()
);

export const deleteUserFromBackend = createAction(
  '[Partner] Delete user from backend',
  props<{ id: number }>()
);

export const deleteUserFromStore = createAction(
  '[Partner] Delete user from store',
  props<{ id: number }>()
);

export const setPartnershipSharing = createAction(
  '[Partner] Set partnership sharing',
  props<{ id: number, enabled: boolean }>()
)

export const addCategoryToStore = createAction(
  '[Partner] Add new custom category to store',
  props<{ id: string, name: string }>()
);

