import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromFilters from './filters.reducer';

export const selectFiltersState = createFeatureSelector<fromFilters.HubFiltersState>(
  fromFilters.featureKey
);

export const selectViewFilter = createSelector(selectFiltersState, state => state.viewFilter);

export const selectViewId = createSelector(selectFiltersState, state => state.viewId);
