import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUaConfig from './ua-config.reducer';

export const selectUaConfigState = createFeatureSelector<fromUaConfig.HubUaConfigState>(
  fromUaConfig.featureKey
);

export const getLanguageCode = createSelector(selectUaConfigState, (state) => state.languageCode);
export const getUaInspectVars = createSelector(selectUaConfigState, (state) => state.uaInspectVars);
export const getUaType = createSelector(selectUaConfigState, (state) => state.uaType);
export const getPrograms = createSelector(selectUaConfigState, (state) => state.programs);
export const getAirlines = createSelector(selectUaConfigState, (state) => state.airlines);
export const getStatus = createSelector(selectUaConfigState, (state) => state.status);
export const getCountByCategory = createSelector(selectUaConfigState, (state) => state.countByCategory);
export const getCountByStatus = createSelector(selectUaConfigState, (state) => state.countByStatus);
