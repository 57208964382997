import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'numericSuffixDisplay'
})
export class NumericSuffixDisplayPipe implements PipeTransform {

  transform(value: number): string {
    if(value < 1000) {
      return '' + value;
    } else if (value < 1000000) {
      return this.reduceToThreeSigFigs(value) + 'k';
    } else if (value < 1000000000) {
      return this.reduceToThreeSigFigs(value) + 'M';
    } else {
      return this.reduceToThreeSigFigs(value) + 'B';
    }
  }

  reduceToThreeSigFigs(value: number) {
    let power = 0;
    while(value >= 1000) {
      value = value / 10;
      power++;
    }
    value = Math.round(value);
    const decimalPositon = (power%3);
    const stringValue = ''+ value;
    const trailingZeros = stringValue.charAt(2) === '0' ? (stringValue.charAt(1) === '0' ? 2 : 1) : 0;
    const preDecimal = stringValue.substring(0, decimalPositon);
    const postDecimal = (decimalPositon + trailingZeros < 3) ? ('.' + stringValue.substring(decimalPositon, 3-trailingZeros)) : ''
    return decimalPositon === 0 ? stringValue : preDecimal + postDecimal;
  }

}
