import { SavedView, SavedViewOrder } from '@hub-services/interfaces/saved-view';
import { createAction, props } from '@ngrx/store';

export const addEmptyView = createAction(
  '[Saved Views] Add empty view',
  props<{ attribute_type: 'upa' | 'uta' }>()
);

export const addEmptyViewInStore = createAction(
  '[Saved Views] Add empty view in store',
  props<{ savedView: Omit<SavedView, 'id'> }>()
);

export const saveNewViewInApi = createAction(
  '[Saved Views] Save new view in API',
  props<{ savedView: Omit<SavedView, 'id'> }>()
);

export const saveNewViewInStore = createAction(
  '[Saved Views] Save new view in Store',
  props<{ savedView: SavedView }>()
);

export const updateViewInApi = createAction(
  '[Saved Views] Update view in API',
  props<{ savedView: SavedView }>()
);

export const updateViewInStore = createAction(
  '[Saved Views] Update view in Store',
  props<{ savedView: SavedView }>()
);

export const deleteViewInApi = createAction(
  '[Saved Views] Delete view in API',
  props<{ id: number }>()
);

export const deleteViewInStore = createAction(
  '[Saved Views] Delete view in Store',
  props<{ id: number }>()
);

export const resetSavedViews = createAction(
  '[Saved Views] Reset',
);

export const updateOrderInApi = createAction(
  '[Saved Views] Update views order in API',
  props<{ items: SavedViewOrder[] }>()
);

export const updateOrderInStore = createAction(
  '[Saved Views] Update views order in Store',
  props<{ items: SavedViewOrder[] }>()
);

export const getViewsByUaType = createAction(
  '[Saved Views] Get views by UA type',
  props<{ uaType: string }>()
);

export const setViewsInStore = createAction(
  '[Saved Views] Set views in store',
  props<{ items: SavedView[] }>()
);
