import { Injectable } from '@angular/core';
import { Subject, of } from 'rxjs';
import { CabinTargetingMode } from '../uas/editing/targeting/cabin-targeting-mode.enum';
import { UA } from '../uas/ua.interface';
import { Category } from './category';

@Injectable({ providedIn: 'root' })
export class EditingDataService {
  private dataTransferSource = new Subject<EditingData>();
  cachedData: EditingData;

  onLoaded() {
    if (this.cachedData) {
      return of(this.cachedData);
    }

    return this.dataTransferSource.asObservable();
  }

  loaded(data: EditingData) {
    this.cachedData = data;
    this.dataTransferSource.next(data);
  }

  clearData() {
    this.cachedData = null;
  }

  updateForm(form: any): void {

    if (!this.cachedData?.form) {
      return;
    }

    this.cachedData.form = { ...form };
  }

  isIconMissing(): boolean {
    return !this.cachedData?.form?.icon_id;
  }

  /**
   * Validates UPA targeting
   * 
   * Returns FALSE when for a cabin in mode "targeted", no brands were selected
   * Returns TRUE when all cabins in mode "targeted" have the same brands selected
   */
  isValidTargeting(): boolean {

    const cabin_targeting = this.cachedData?.form?.cabin_targeting.filter(targetingRule => targetingRule.mode === CabinTargetingMode.TARGETED);

    if (!cabin_targeting.length) {
      return true;
    }

    // all cabins must have at least one brand
    if (!cabin_targeting.map(targetingRule => targetingRule.brand_ids.length).every(item => item > 0)) {
      return false;
    }

    // all cabins must have the same brands
    return new Set(
      cabin_targeting.map(targetingRule => JSON.stringify(targetingRule.brand_ids))
    ).size === 1;
  }
}

export interface EditingData {
  form: Partial<UA>;
  uaStandardCategory: Category;
  standardCategories: Category[];
  utaCategoryRules?: any[];
}
