import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utaVersion'
})
export class UtaVersionPipe implements PipeTransform {

  transform(uta_source: string): number {
    return uta_source === 'atpco' ? 2 : 1;
  }

}
