import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'panelColumns'
})
export class PanelColumnsPipe implements PipeTransform {

  transform(items: any[], maxColumns = 3): string {
    let columns = '1fr';
    if (!!items?.length) {
      items.forEach((cabin, index) => {
        if (index > 0 && index < maxColumns) {
          columns += ' 1fr';
        }
      });
    }
    return columns;
  }

}
