import { Injectable } from '@angular/core';

import { setSessionData } from '@hub-store/session/session.actions';
import { getSession } from '@hub-store/session/session.selectors';
import { Store } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/operators';
import { of, switchMap, take, tap } from 'rxjs';
import { SessionsService } from '@hub-services/sessions.service';
import { PartnersService } from '@hub-services/partners.service';
import * as SelectedPartnerActions from '@hub-store/selected-partner/selected-partner.actions';
import * as HubStoreActions from '@hub-store/hub-store.actions';
import * as UaConfigActions from '@hub-store/ua-config/ua-config.actions';
import { getSelectedPartner } from '@hub-store/selected-partner/selected-partner.selectors';

@Injectable({ providedIn: 'root' })
export class SessionResolve  {
  constructor(
    private sessionsService: SessionsService,
    private partnersService: PartnersService,
    private store: Store) { }

  resolve() {

    return this.store.select(getSession)
      .pipe(
        concatLatestFrom(() => this.store.select(getSelectedPartner)),
        take(1),
        switchMap(([session, partner]) => {
          return session?.user_id
            ? of(session)
            : this.sessionsService
              .getSession()
              .pipe(
                switchMap((sessionData: any) => {

                  if (!sessionData) {
                    return of(HubStoreActions.routerNavigate({
                      commands: ['/login']
                    }));
                  }

                  this.store.dispatch(setSessionData({ data: sessionData }));
                  if (!sessionData?.partner_id) {
                    // partner is not selected, return just user data
                    return of(sessionData);
                  }

                  if (sessionData.partner_id === partner?.id) {
                    // partner didn't changed, return what we already have
                    return of(sessionData);
                  }

                  // at this point, new partner is selected, so get their data and save it in the store
                  return this.partnersService
                    .getPartner(sessionData.partner_type, sessionData.partner_id)
                    .pipe(
                      take(1),
                      tap(partner => {
                        this.store.dispatch(SelectedPartnerActions.setPartner({ partner }));
                        this.store.dispatch(UaConfigActions.setLanguageCode({ languageCode: partner.default_language_code }))
                      }),
                      switchMap(() => of(sessionData))
                    )
                })
              );
        }));

  }
}
