import { catchError, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api';
import { of } from 'rxjs';


export interface Session {
  data: SessionDetail;
  included?: any;
}

export interface SessionDetail {
  partner_id: string;
  user_id: number;
  partner_type?: string;
  partner_uid?: string;
  partner_carrier_code?: string;
  user_partner_uid?: string;
  user_partner_type?: string;
  user_carrier_code?: string;
}

export interface Creds {
  email: string;
  password: string;
}

@Injectable({ providedIn: 'root' })
export class SessionsService {
  path = '/api';

  constructor(
    private apiService: ApiService,
  ) { }

  getSession(dataOnly: boolean = true) {
    const requestPath = dataOnly
      ? `${this.path}/session`
      : `${this.path}/session?include=user,partner`;

    return this.apiService.get(requestPath).pipe(
      catchError(() => of({})),
      switchMap((response: Session) => dataOnly ? of(response.data) : of(response)),
    );
  }

  signIn(creds: Creds) {
    const data = {
      user: creds,
    };
    return this.apiService.post(`${this.path}/session`, data);
  }

  signOut() {
    return this.apiService.delete(`${this.path}/session`).pipe(
      map(() => {
        localStorage.removeItem('currentUser');
        window.location.href = '/login';
      }),
    );
  }

  signInPartner(partnerId: number) {
    const data = {
      partner_id: partnerId,
    };
    return this.apiService.put(`${this.path}/session`, data);
  }

  getUserForPasswordForm(resetToken: string) {
    return this.apiService
      .get(`${this.path}/password/edit?reset_password_token=${resetToken}`);
  }

  resetPassword(email: string) {
    const data = {
      user: { email },
    };
    return this.apiService.post(`${this.path}/password`, data);
  }

  updatePassword(password: string, passwordConfirmation: string, resetToken: string) {
    const data = {
      user: {
        reset_password_token: resetToken,
        password: password,
        password_confirmation: passwordConfirmation,
      },
    };
    return this.apiService.put(`${this.path}/password`, data);
  }
}
