import * as Rollbar from 'rollbar';
import { environment } from '@hub-env/environment';
import { ErrorHandler, Injectable, Inject, InjectionToken } from '@angular/core';

const rollbarConfig = {
  accessToken: environment.ROLLBAR_CLIENT_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  // captureUncaught: false,
  // captureUnhandledRejections: false,
  autoInstrument: {
    log: false,
  },
  payload: {
    environment: environment.ROLLBAR_ENVIRONMENT,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: environment.version,
        guess_uncaught_frames: true
      }
    }
  },
  reportLevel: 'error',
} as any;

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

@Injectable({ providedIn: 'root' })
export class RollbarErrorHandler implements ErrorHandler {

  // RegEx to detect errors like "ChunkLoadError: Loading chunk 166 failed."
  private chunkFailedMessage = /Loading chunk [\d]+ failed/;

  constructor(@Inject(RollbarService) private rollbar: Rollbar) { }

  handleError(err: any): void {

    if (!environment.production || this.isElectron()) {
      console.log('Error: ', err, err.originalError);
      return;
    }

    if (this.chunkFailedMessage.test(err.originalError || err)) {
      window.location.reload();
    } else {
      this.rollbar.error(err.originalError || err);
    }

  }

  // GitLab pipelines runs in Electron, we don't need these errors
  private isElectron(): boolean {

    // Renderer process
    if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process['type'] === 'renderer') {
      return true;
    }

    // Main process
    if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
      return true;
    }

    // Detect the user agent when the `nodeIntegration` option is set to true
    if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
      return true;
    }

    return false;

  }
}
