import { MessageType } from '@hub-services/interfaces/message-type.enum';
import { Message } from '@hub-services/interfaces/message.model';
import { createAction, props } from '@ngrx/store';

export const createMessage = createAction(
  '[Message] Create message',
  props<{ message: Message }>()
);

export const createMessages = createAction(
  '[Message] Create messages',
  props<{ messageType: MessageType, messages: string[] }>()
);

export const createDefaultMessage = createAction(
  '[Message] Create default message',
);

export const createErrorMessage = createAction(
  '[Message] Create error message',
  props<{ errorText: string }>()
);

export const createExtendedErrorMessage = createAction(
  '[Message] Create extended error message',
  props<{ error: any }>()
);

export const clearMessages = createAction(
  '[Message] Clear messages',
);

export const setAppSnackbar = createAction(
  '[Message] Set App Snackbar',
  props<{ message: string }>()
);
