import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getNameByCode'
})
export class GetNameByCodePipe implements PipeTransform {

  transform(collection: any[], code: string): string {
    return collection?.find(item => item.code === code)?.name ?? 'N/A';
  }

}
