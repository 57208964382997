import { Pipe, PipeTransform } from '@angular/core';
import { Cabin } from '@hub-services/interfaces/cabin';

@Pipe({
  name: 'getCabinColor'
})
export class GetCabinColorPipe implements PipeTransform {

  transform(cabinId: number, cabins?: Cabin[], cabinIds?: number[]): string {

    if (!cabinIds || cabinIds.includes(cabinId)) {
      return cabins?.find(c => c.id === cabinId)?.color ?? '#dedede';
    }

    return '#dedede';
  }

}
