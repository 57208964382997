import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isCategoryDisabled'
})
export class IsCategoryDisabledPipe implements PipeTransform {

  transform(isCommunityParticipant: boolean, category: any, enabledCategories: string[]): boolean {
    return isCommunityParticipant ? !enabledCategories.includes(category.id) : false;
  }

}
