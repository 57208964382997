import {environment} from '@hub-env/environment';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {saveAs} from 'file-saver';
import { indexOf } from 'lodash';

@Injectable({providedIn: 'root'})
export class ApiService {
  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });

  exportHeaders: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'text/csv',
  });

  apiUrl: string = environment.API_URL;

  constructor(private http: HttpClient) {}

  private getJson(response: any) {
    return response;
  }

  private checkForError(response: any): any {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      const error = new Error(response.statusText);
      error['response'] = response;
      console.error(error);
      throw error;
    }
  }

  get(path: string): Observable<any> {
    return this.http.get(`${this.apiUrl}${path}`, {headers: this.headers}).pipe(map(this.getJson));
  }

  getWithLanguage(path: string, languageCode: string = 'en'): Observable<any> {
    const headersLang: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': languageCode,
    });
    const options = {
      headers: headersLang,
    };
    return this.http.get(`${this.apiUrl}${path}`, options).pipe(map(this.getJson));
  }

  getParams(path: string, data: any): Observable<any> {
    let params = new HttpParams();
    for (const key in data) {
      if (key) {
        params = params.append(key, data[key]);
      }
    }
    const options = {
      params: params,
      headers: this.headers,
    };
    return this.http.get(`${this.apiUrl}${path}`, options).pipe(map(this.getJson));
  }

  post(path: string, body: any): Observable<any> {
    return this.http
      .post(`${this.apiUrl}${path}`, JSON.stringify(body), {headers: this.headers})
      .pipe(map(this.getJson));
  }

  
  postExport(path: string, body: any, name: string): Observable<any> {
    return this.http
      .post(`${this.apiUrl}${path}`, JSON.stringify(body), {headers: this.exportHeaders, responseType: 'text'})
      .pipe(tap((response: string) => {
        const blob = new Blob(["\ufeff", this.formatCsvResponse(response)], {type: 'text/csv;charset=utf-16'})
        saveAs(blob, `${name}.csv`)
      }));
  }

  postForm(path: string, formData: FormData): Observable<any> {
    return this.http.post(`${this.apiUrl}${path}`, formData).pipe(map(this.getJson));
  }

  put(path: string, body: any): Observable<any> {
    return this.http
      .put(`${this.apiUrl}${path}`, JSON.stringify(body), {headers: this.headers})
      .pipe(map(this.getJson));
  }

  putForm(path: string, formData: FormData): Observable<any> {
    return this.http.put(`${this.apiUrl}${path}`, formData).pipe(map(this.getJson));
  }

  patch(path: string, body: any): Observable<any> {
    return this.http
      .patch(`${this.apiUrl}${path}`, JSON.stringify(body), {headers: this.headers})
      .pipe(map(this.getJson));
  }

  delete(path: string): Observable<any> {
    return this.http
      .delete(`${this.apiUrl}${path}`, {headers: this.headers})
      .pipe(map(this.getJson));
  }

  deleteBody(path: string, body: any): Observable<any> {
    return this.http
      .request('delete', `${this.apiUrl}${path}`, {headers: this.headers, body: body})
      .pipe(map(this.getJson));
  }

  setHeaders(headers: any) {
    Object.keys(headers).forEach((header) => this.headers.set(header, headers[header]));
  }

  formatCsvResponse(response: string): string {
    let uppercaseHeaders = response.substring(0, response.indexOf('\n'))
    .replace(/_/g, ' ')
    .replace(/\w\S*/g, function(text) { return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase() })
    .replace(/,[a-z]/g, function(text) { return text.toUpperCase() });;
    return uppercaseHeaders + response.substring(response.indexOf('\n'));
  }

  getCsv(path: string) {
    window.location.href = `${this.apiUrl}${path}`;
  }
}
