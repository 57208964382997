import { User, UserService } from '@hub-services/user.service';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { getUser } from '@hub-store/user/user.selectors';
import { setUser } from '@hub-store/user/user.actions';
import { concatMap, of, take, tap } from 'rxjs';
import { RoleType } from '../../admin/role-type.enum';

@Injectable({ providedIn: 'root' })
export class UserResolve  {
  constructor(private userService: UserService, private store: Store) { }

  resolve() {
    return this.store.select(getUser)
      .pipe(
        take(1),
        concatMap(user => user?.role
          ? of(user)
          : this.userService
            .updateUser()
            .pipe(
              tap((userData: User) => this.store.dispatch(setUser({
                user: {
                  ...userData,
                  // isCommunityParticipant: user.role === RoleType.COMMUNITY_PARTICIPANT, // @todo
                  // isCollaborator: user.role === RoleType.COLLABORATOR,
                  isCommunityParticipant: true,
                  isCollaborator: false,
                }
              })))
            )));
  }
}
