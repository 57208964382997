import { createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';
import { RoleType } from '../../admin/role-type.enum';

export const featureKey = 'user';

export interface UserState {
  role: RoleType;
  scope: string;
  accepted_terms: boolean;
  isCollaborator: boolean;
  isCommunityParticipant: boolean;
}

export const initialState: UserState = {
  role: null,
  scope: null,
  accepted_terms: null,
  isCollaborator: null,
  isCommunityParticipant: null,
};

export const reducer = createReducer(
  initialState,

  on(UserActions.setUser, (state, action) => ({
    ...state,
    ...action.user,
    isCollaborator: action.user.role === RoleType.COLLABORATOR,
    isCommunityParticipant: action.user.role === RoleType.COMMUNITY_PARTICIPANT,
  })),

);
