import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getUaType'
})
export class GetUaTypePipe implements PipeTransform {

  transform(isUpa: boolean): string {
    return isUpa ? 'UPA' : 'UTA';
  }

}
