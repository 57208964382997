import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getDetailName'
})
export class GetDetailNamePipe implements PipeTransform {

  transform(detail: any, option_type_id: string): string {
    if (option_type_id === 'terminal_list') {
      return detail.airport_code + ' ' + detail.name;
    }

    return detail.name;
  }

}
