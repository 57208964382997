import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as FiltersActions from './filters.actions';
import * as FiltersSelectors from './filters.selectors';
import * as HubStoreActions from '@hub-store/hub-store.actions';
import { Store } from "@ngrx/store";
import { concatLatestFrom } from "@ngrx/operators";
import { switchMap, } from 'rxjs/operators';
import { of } from "rxjs";
import { FilterProperty } from "./filters.reducer";

@Injectable()
export class FiltersEffects {

  updateQueryParams$ = createEffect(() => this.actions$.pipe(
    ofType(FiltersActions.updateQueryParams),
    concatLatestFrom(() => this.store.select(FiltersSelectors.selectFiltersState)),
    switchMap(([action, state]) => {

      const params: any = {};
      if (state.viewFilter) {
        params.view = state.viewFilter;
      }
      if (state.groupByCategory) {
        params.group_upas = true;
      }
      if (state.filteredCabinIds.length) {
        params.global_cabins = state.filteredCabinIds;
      }
      if (state.filteredAirlineIds.length) {
        params.global_airlines = state.filteredAirlineIds;
      }
      if (state.filteredCategoryIds.length) {
        params.global_categories = state.filteredCategoryIds;
      }
      if (state.filteredBrandIds.length) {
        params.global_brands = state.filteredBrandIds;
      }
      if (state.filteredUpaIds.length) {
        params.global_upas = state.filteredUpaIds;
      }
      if (state.filteredSubfleetIds.length) {
        params.global_subfleets = state.filteredSubfleetIds;
      }
      if (state.filteredUpaSourceId) {
        params.global_upa_source = state.filteredUpaSourceId;
      }
      if (state.flightSearch?.date) {
        params.flight_from = state.flightSearch.departureCode;
        params.flight_to = state.flightSearch.arrivalCode;
        params.flight_date = state.flightSearch.date;
      }
      if (state.viewId) {
        params.view_id = state.viewId;
      }

      return of(HubStoreActions.routerNavigate({
        commands: [],
        extras: {
          queryParams: params,
        }
      }))
    })
  ));

  setFilterProp$ = createEffect(() => this.actions$.pipe(
    ofType(FiltersActions.setFilterProp),
    switchMap(() => of(FiltersActions.updateQueryParams()))
  ));

  resetFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FiltersActions.resetFilters),
      switchMap(() => of(HubStoreActions.routerNavigate({
        commands: [],
      })))
    )
  });

  setBrandIds$ = createEffect(() => this.actions$.pipe(
    ofType(FiltersActions.setBrandIds),
    switchMap(action => of(FiltersActions.setFilterProp({
      name: FilterProperty.filteredBrandIds,
      value: Array.from(new Set(this.mapToNumber(action.ids))),
    })))
  ));

  setUpaIds$ = createEffect(() => this.actions$.pipe(
    ofType(FiltersActions.setUpaIds),
    switchMap(action => of(FiltersActions.setFilterProp({
      name: FilterProperty.filteredUpaIds,
      value: Array.from(new Set(this.mapToNumber(action.ids))),
    })))
  ));

  setCabinIds$ = createEffect(() => this.actions$.pipe(
    ofType(FiltersActions.setCabinIds),
    switchMap(action => of(FiltersActions.setFilterProp({
      name: FilterProperty.filteredCabinIds,
      value: Array.from(new Set(this.mapToNumber(action.ids))),
    })))
  ));

  constructor(
    private actions$: Actions,
    private store: Store,
  ) { }


  private mapToNumber(ids: any[]): number[] {
    return ids.map(id => Number(id));
  }
}
