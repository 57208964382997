import { SavedView } from '@hub-services/interfaces/saved-view';
import { createReducer, on } from '@ngrx/store';
import * as SavedViewsActions from './saved-views.actions';

export const featureKey = 'savedViews';

export interface HubSavedViewsState {
  items: SavedView[];
}

export const initialState: HubSavedViewsState = {
  items: [],
};

export const reducer = createReducer(
  initialState,

  on(SavedViewsActions.addEmptyViewInStore, (state, action) => ({
    ...state,
    items: [...state.items, action.savedView]
  })),
  on(SavedViewsActions.saveNewViewInStore, (state, action) => {
    const newState = JSON.parse(JSON.stringify(state));
    newState.items[newState.items.length - 1] = action.savedView;

    return { ...newState };
  }),
  on(SavedViewsActions.updateViewInStore, (state, action) => {
    const newState = JSON.parse(JSON.stringify(state));
    const idx = newState.items.findIndex(item => item.id === action.savedView.id);

    newState.items[idx] = { ...action.savedView };

    return { ...newState }
  }),
  on(SavedViewsActions.setViewsInStore, (state, action) => ({
    ...state,
    items: [...action.items].sort((a, b) => a.position - b.position)
  })),
  on(SavedViewsActions.deleteViewInStore, (state, action) => ({
    items: state.items.filter(item => item.id !== action.id)
  })),
  on(SavedViewsActions.resetSavedViews, state => ({
    ...state,
    ...initialState,
  })),
  on(SavedViewsActions.updateOrderInStore, (state, action) => {

    const newState = JSON.parse(JSON.stringify(state));

    // update saved items position property
    newState.items = newState.items.map(item => ({
      ...item,
      position: action.items.find(actionItem => actionItem.id === item.id).position,
    }));

    // sort by position
    newState.items = newState.items.sort((a, b) => a.position - b.position);

    return { ...newState };
  }),

);
