import { createAction, props } from '@ngrx/store';

export const setDateRange = createAction(
  '[Channels] Set date range',
  props<{ startDate: string, endDate: string; }>()
);

export const setActiveChannelReport = createAction(
  '[Channels] Set active channel report',
  props<{ activeChannelReport: any }>()
);
