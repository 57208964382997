import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as MessagesActions from './messages.actions';
import { MessageType } from "@hub-services/interfaces/message-type.enum";


@Injectable()
export class MessagesEffects {

  createMessages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesActions.createMessages),
      switchMap(action => {
        if (!action.messages?.length) {
          return of(MessagesActions.createDefaultMessage())
        }

        let actions = [];

        for (const message of action.messages) {
          actions.push(MessagesActions.createMessage({
            message: {
              type: action.messageType,
              text: message,
            }
          }));
        }

        return from(actions);
      })
    );
  });

  createExtendedErrorMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesActions.createExtendedErrorMessage),
      switchMap(action => {
        if (action.error.status === 422) {
          return of(MessagesActions.createMessages({
            messageType: MessageType.ALERT,
            messages: action.error.error.data?.messages ?? action.error.error?.messages
          }));
        }

        if (action.error.status === 403) {

          return of(MessagesActions.createMessages({
            messageType: MessageType.ALERT,
            messages: action.error.error?.error ?? [`We're sorry. Something went wrong.`]
          }));
        }

        if(action.error.message) {
          return of(MessagesActions.createMessages({
            messageType: MessageType.ALERT,
            messages: [action.error.message]
          }));
        }

        return of(MessagesActions.createDefaultMessage())
      }),
    );
  });

  constructor(
    private actions$: Actions,
  ) { }

}
