import { Injectable, NgModule } from '@angular/core';
import {
  PreloadingStrategy,
  Route,
  RouterModule,
  Routes,
  mapToCanActivate,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AdminAuthGuard } from './auth/admin-auth.guard';
import { NotFoundComponent } from './errors/404.component';
import { SessionResolve } from '@hub-resolvers/session.resolve';
import { UserResolve } from '@hub-resolvers/user.resolve';
import { environment } from '@hub-env/environment';
import { UpaCountsGuard } from './reports/upa-counts/upa-counts.guard';
import { HubFlightSearchGuard } from './hub-flight-search/hub-flight-search.guard';

@Injectable()
export class CustomPreload implements PreloadingStrategy {
  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    return route.data && route.data.preload ? fn() : of(null);
  }
}

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'admin/airlines',
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: mapToCanActivate([AdminAuthGuard]),
    resolve: {
      session: SessionResolve,
      user: UserResolve,
    },
  },
  {
    path: 'channels',
    loadChildren: () =>
      import('./channels/channels.module').then((m) => m.ChannelsModule),
    resolve: {
      session: SessionResolve,
      user: UserResolve,
    },
  },
  {
    path: 'observer',
    loadChildren: () =>
      import('./observers/observers.module').then((m) => m.ObserversModule),
    data: {
      skipBreadcrumb: true,
    },
  },
  {
    path: 'upas',
    loadChildren: () => import('./upas/upas.module').then((m) => m.UpasModule),
  },
  {
    path: 'upasV2',
    loadChildren: () => import('./upa-v2/upa-v2.module').then((m) => m.UpaV2Module),
  },
  {
    path: 'upa-metrics',
    loadChildren: () =>
      import('./upa-metrics/upa-metrics.module').then(
        (m) => m.UpaMetricsModule
      ),
  },
  {
    path: 'gallery',
    loadChildren: () =>
      import('./gallery/gallery.module').then((m) => m.GalleryModule),
  },
  {
    path: 'airline/flight-search',
    loadChildren: () =>
      import('./airline-flight-search/airline-flight-search.module').then(
        (m) => m.AirlineFlightSearchModule
      ),
    resolve: {
      session: SessionResolve,
      user: UserResolve,
    },
    canActivate: mapToCanActivate([HubFlightSearchGuard]),
  },
  {
    path: 'flight-search',
    loadChildren: () =>
      import('./general-flight-search/general-flight-search.module').then(
        (m) => m.GeneralFlightSearchModule
      ),
    resolve: {
      session: SessionResolve,
      user: UserResolve,
    },
    canActivate: mapToCanActivate([HubFlightSearchGuard]),
  },
  {
    path: 'utas',
    loadChildren: () => import('./utas/utas.module').then((m) => m.UtasModule),
    resolve: {
      session: SessionResolve,
      user: UserResolve,
    },
  },
  {
    path: 'utasv1',
    loadChildren: () =>
      import('./utas-legacy/utas-legacy.module').then(
        (m) => m.UtasLegacyModule
      ),
    resolve: {
      session: SessionResolve,
      user: UserResolve,
    },
  },
  {
    path: 'mediawindow',
    loadChildren: () =>
      import('./mediawindow/media-window.module').then(
        (m) => m.MediaWindowModule
      ),
  },
  {
    path: 'lists',
    loadChildren: () =>
      import('./lists/targeting-lists.module').then(
        (m) => m.TargetingListsModule
      ),
    resolve: {
      session: SessionResolve,
      user: UserResolve,
    },
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsModule),
    canActivate: mapToCanActivate([AdminAuthGuard]),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
    canActivate: mapToCanActivate([AdminAuthGuard]),
  },
  {
    path: 'terms',
    loadChildren: () =>
      import('./terms/terms.module').then((m) => m.TermsModule),
  },
  {
    path: 'dashboard',
    redirectTo: 'channels/usage',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./auth/auth.module').then((m) => m.RhAuthModule),
  },
  {
    path: 'password',
    loadChildren: () =>
      import('./auth/auth.module').then((m) => m.RhAuthModule),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsModule),
    resolve: {
      session: SessionResolve,
      user: UserResolve,
    },
    canActivate: mapToCanActivate([UpaCountsGuard]),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreload,
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
