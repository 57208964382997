import { SavedView, SavedViewOrder } from './interfaces/saved-view';
import { Injectable } from '@angular/core';
import { ApiService } from './api';
import { Observable } from 'rxjs';
import { FilterProperty, HubFiltersState } from '@hub-store/filters/filters.reducer';

@Injectable({ providedIn: 'root' })
export class SavedViewsService {
  path: string = '/api';
  views: SavedView[];

  private savableViewKeys: string[] = [
    FilterProperty.viewFilter,
    FilterProperty.groupByCategory,
    FilterProperty.filteredCategoryIds,
    FilterProperty.filteredBrandIds,
    FilterProperty.filteredCabinIds,
    FilterProperty.filteredSubfleetIds,
    FilterProperty.flightSearch,
  ];

  constructor(private apiService: ApiService) { }

  getViews(type: string): Observable<SavedView[]> {
    const typeClean = type.length > 3 ? type.slice(0, -1) : type;
    const typeLower = typeClean.toLowerCase();

    return this.apiService
      .get(`${this.path}/custom_attribute_views?attribute_type=${typeLower}`);
  }

  updateView(view: SavedView) {
    return this.apiService
      .put(`${this.path}/custom_attribute_views/${view.id}`, {
        data: {
          name: view.name,
          filters: view.filters,
        },
      });
  }

  updateOrder(updatedViews: SavedViewOrder[]) {
    return this.apiService
      .patch(`${this.path}/custom_attribute_views`, {
        data: {
          custom_attribute_views: updatedViews,
        },
      });
  }

  addView(view: Omit<SavedView, 'id' | 'position'>): Observable<SavedView> {
    if (!view.name?.length) {
      view.name = 'Untitled';
    }
    return this.apiService
      .post(`${this.path}/custom_attribute_views`, {
        data: view,
      });
  }

  deleteView(id: number) {
    return this.apiService
      .delete(`${this.path}/custom_attribute_views/${id}`);
  }

  getFilterValues(state: HubFiltersState) {
    const viewsObject = {};
    Object.keys(state).filter((key) => {
      return (
        this.savableViewKeys.includes(key) &&
        (state[key].length || state[key] === true || state[key])
      );
    }).forEach(
      (key) => {
        if (key === FilterProperty.viewFilter) {
          viewsObject['view'] = state[key];
        } else if (key === FilterProperty.filteredCategoryIds) {
          viewsObject['global_categories'] = state[key];
        } else if (key === FilterProperty.filteredCabinIds) {
          viewsObject['global_cabins'] = state[key];
        } else if (key === FilterProperty.filteredBrandIds) {
          viewsObject['global_brands'] = state[key];
        } else if (key === FilterProperty.filteredSubfleetIds) {
          viewsObject['global_subfleets'] = state[key];
        } else if (key === FilterProperty.flightSearch) {
          viewsObject['flight_from'] = state[key].departureCode;
          viewsObject['flight_to'] = state[key].arrivalCode;
          viewsObject['flight_date'] = state[key].date;
        }
      },
    );
    return viewsObject;
  }
}
