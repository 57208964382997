
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multiValuePlaceholder'
})
export class MultiValuePlaceholderPipe implements PipeTransform {

  transform(valueList: string[], plural: string, mapping?: {name: string, id: any}[]): string {
    return valueList?.length === 1 ? (mapping ? mapping.find(val => val.id === valueList[0]).name : valueList[0]) : valueList.length + ' ' + plural;
  }

}
