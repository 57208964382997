import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isInArray',
  pure: false,
})
export class IsInArrayPipe implements PipeTransform {

  transform(itemIds: string[], id: string): boolean {
    return !!itemIds?.includes(id);
  }

}
