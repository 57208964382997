import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'not-found',
  templateUrl: '404.component.html',
  styles: [`
    .error-page__image {
      margin: 5vh auto 0;
      max-width: 450px;
      width: 98%;
    }

    .error-page__image img {
      max-width: 100%;
    }

    .error-page__dialog {
      margin: 0 auto 100px;
      text-align: center;
    }

    .error-page__hd {
      font-size: 32px;
      font-weight: 400;
      line-height: 1;
      margin: 0;
      padding-bottom: 3px;
    }

    .error-page__text {
      font-size: 16px;
      margin: .5em 0;
    }

    .footer {
      background: #fff;
      margin: -1px 0 0
    }

    .footer:before,
    .footer:after {
      content: "";
      display: table
    }

    .footer:after {
      clear: both
    }

    .footer__content {
      max-width: 338px;
      margin: 0 auto;
      padding: 30px 10px
    }

    .footer__content:before,
    .footer__content:after {
      content: "";
      display: table
    }

    .footer__content:after {
      clear: both
    }

    @media screen and (min-width:348px) {
      .footer__content {
        padding: 30px 0
      }
    }

    @media screen and (min-width:768px) {
      .footer__content {
        padding: 40px 0;
        max-width: 426px;
      }
    }

    .footer__copyright {
      color: #8e949b !important;
      font-size: 11px;
      margin: 0 0 16px
    }

    @media screen and (min-width:768px) {
      .footer__copyright {
        width: 256px;
        float: left
      }
    }

    .footer__logo-link {
      display: block;
      margin: 0 0 12px;
      width: 160px
    }

    .footer__logo-link svg {
      fill: #1d4e8f
    }

    .footer__copyright-text {
      margin: 0 0 4px
    }

    .copyright__link {
      display: inline-block;
      margin: 0 12px 12px 0
    }

    .copyright__link>a {
      color: #8e949b;
      text-decoration: none
    }

    @media screen and (min-width:768px) {
      .footer__links {
        float: left;
        width: 170px
      }
    }

    .footer__link-list {
      font-size: 14px;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      float: left;
      margin: 0 0 16px;
      width: 50%
    }

    @media screen and (-webkit-min-device-pixel-ratio:2) {
      .footer__link-list {
        font-weight: 400;
        -webkit-font-smoothing: inherit
      }
    }

    @media screen and (min-width:768px) {
      .footer__link-list {
        width: 100%
      }
    }

    .footer__list-hd {
      color: #1d4e8f;
      margin: 0 0 8px
    }

    .footer__link {
      color: #4c5967;
      display: block;
      margin: 0 0 8px;
      text-decoration: none
    }

    .footer__link:hover {
      color: #06c
    }
  `]
})

export class NotFoundComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
