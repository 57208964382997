import { Injectable } from '@angular/core';

import { environment } from '@hub-env/environment';

@Injectable({
  providedIn: 'root'
})
export class UpaCountsGuard  {
  canActivate(): boolean {
    return environment.featureFlag.countUpaReport;
  }

}
