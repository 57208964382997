import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'panelWidth'
})
export class PanelWidthPipe implements PipeTransform {

  transform(items: any[]): string {
    let width = 831;
    if (items?.length < 4) {
      width = items.length * 240;
    }
    return `${width}px`;
  }

}
