import { createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';
import * as ChannelUsageActions from './channel-usage.actions';
import { DateRange } from './date-range.model';

export const featureKey = 'channelUsage';

export interface HubChannelUsageState {
  dateRange: DateRange;
  activeChannelReport: {
    partner: any;
    report: any;
    routeReport: any;
    photoReport: any;
  };
}

export const initialState: HubChannelUsageState = {
  dateRange: {
    startDate: DateTime.now().minus({ month: 1 }).toISODate(),
    endDate: DateTime.now().minus({ day: 1 }).toISODate(),
  },
  activeChannelReport: {} as any,
};

export const reducer = createReducer(
  initialState,

  on(ChannelUsageActions.setDateRange, (state, action) => ({
    ...state,
    dateRange: {
      startDate: action.startDate,
      endDate: action.endDate,
    }
  })),
  on(ChannelUsageActions.setActiveChannelReport, (state, action) => ({
    ...state,
    activeChannelReport: action.activeChannelReport,
  })),

);
