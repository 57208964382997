import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from './user.reducer';

export const selectUserState = createFeatureSelector<fromUser.UserState>(
  fromUser.featureKey
);

export const getUser = createSelector(selectUserState, (state) => state);

export const getIsCommunityParticipant = createSelector(selectUserState, (state) => !!state?.isCommunityParticipant);
export const getIsCollaborator = createSelector(selectUserState, (state) => !!state?.isCollaborator);
