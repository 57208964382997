import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPropById'
})
export class GetPropByIdPipe implements PipeTransform {

  /**
   *Search for an item of `values` where `propertyName` matches `keyword`. 
   By default, property type is considered to be a `string`, but could be changed to `number`.
   *
   * @param values Collection of items to search in 
   * @param keyword Value to search for
   * @param propertyName Propery name to be searched for matching keyword
   * @param propertyType Data type - string or number
   * @param defaultValue Default value to be returned if there is no match
   * @returns Value of `propertyName` from first matched object or default value.
   */
  transform(values: any[], keyword: any, propertyName: string, propertyType: 'string' | 'number', defaultValue: any): any {

    return values?.find((item: any) => {
      return propertyType === 'number'
        ? Number(item.id) === Number(keyword)
        : item.id === keyword;

    })?.[propertyName] ?? defaultValue;
  }

}
