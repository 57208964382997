import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import * as HubActions from '@hub-store/hub-store.actions';
import { Router } from "@angular/router";

@Injectable()
export class HubDefaultEffects {

  routerNavigate$ = createEffect(() => this.actions$.pipe(
    ofType(HubActions.routerNavigate),
    tap((action) => this.router.navigate(action.commands, action.extras))
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private router: Router,
  ) { }

}
