import { Program } from '@hub-services/programs.service';
import { createAction, props } from '@ngrx/store';
import { UaStatus } from '../../uas/ua-status.enum';
import { UpasByStatusCount } from '../../upas/upas-by-status-count.model';
import { UaInspectVars } from './ua-inspect-vars.model';


export const setLanguageCode = createAction(
  '[UA Config] Set language code',
  props<{ languageCode: string }>()
);

export const setDefaultLanguageCode = createAction(
  '[UA Config] Set default language code',
);

export const setUaInspectVars = createAction(
  '[UA Config] Set UaInspectVars',
  props<{ data: UaInspectVars }>()
);

export const resetUaInspectVars = createAction(
  '[UA Config] Reet UaInspectVars',
);

export const setUaType = createAction(
  '[UA Config] Set uaType',
  props<{ uaType: string }>()
);

export const setPrograms = createAction(
  '[UA Config] Set programs',
  props<{ programs: Program[] }>()
);

export const resetPrograms = createAction(
  '[UA Config] Reset programs',
);

export const setAirlines = createAction(
  '[UA Config] Set airlines',
  props<{ airlines: any[] }>()
);

export const resetAirlines = createAction(
  '[UA Config] Reset airlines',
);

export const setStatus = createAction(
  '[UA Config] Set status',
  props<{ status: string }>()
);

export const resetStatus = createAction(
  '[UA Config] Reset status',
);

export const resetUaConfig = createAction(
  '[UA Config] Reset Ua Config',
);

export const countUpasByCategory = createAction(
  '[UA Config] Count UPAs by category',
  props<{ upas: any[] }>()
);

export const setCategoryCount = createAction(
  '[UA Config] Set UPAs count by category',
  props<{ stats: { [key: string]: number } }>()
);

export const requestUpaStatusCount = createAction(
  '[UA Config] Request data for UPAs status count',
);

export const setUpaStatusCount = createAction(
  '[UA Config] Set UPAs count by status',
  props<{ stats: UpasByStatusCount[] }>()
);