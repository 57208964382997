import { TitleService } from '@hub-services/title.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { RhCommonModule } from '@hub-common/rh-common.module';
import { Angulartics2Module } from 'angulartics2';
import { environment } from '@hub-env/environment';

import { ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';

import { RollbarService, rollbarFactory, RollbarErrorHandler } from './rollbar';
import { AppRoutingModule, CustomPreload } from './app-routing.module';
import { HubUiModule } from '@atpco/hub-ui';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as fromChannels from '@hub-store/channel-usage/channel-usage.reducer';
import * as fromMedia from '@hub-store/media/media.reducer';
import * as fromPartner from '@hub-store/selected-partner/selected-partner.reducer';
import * as fromPartnersList from '@hub-store/partners-list/partners-list.reducer';
import * as fromSession from '@hub-store/session/session.reducer';
import * as fromHelp from '@hub-store/help/help.reducer';
import * as fromUser from '@hub-store/user/user.reducer';
import * as fromUaConfig from '@hub-store/ua-config/ua-config.reducer';
import * as fromMessages from '@hub-store/messages/messages.reducer';
import * as fromFilters from '@hub-store/filters/filters.reducer';
import * as fromSavedViews from '@hub-store/saved-views/saved-views.reducer';
import { EffectsModule } from '@ngrx/effects';
import { HubDefaultEffects } from '@hub-store/hub-store.effects';
import { MessagesEffects } from '@hub-store/messages/messages.effects';
import { FiltersEffects } from '@hub-store/filters/filters.effects';
import { DatePipe } from '@angular/common';
import { SavedViewsEffects } from '@hub-store/saved-views/saved-views.effects';
import { WINDOW_PROVIDERS } from './shared/window.providers';
import { NgxEchartsModule } from 'ngx-echarts';
import { AgGridModule } from 'ag-grid-angular';

export const providers = [
  CustomPreload,
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: ErrorHandler, useClass: RollbarErrorHandler },
  { provide: RollbarService, useFactory: rollbarFactory },
  DatePipe,
  ...WINDOW_PROVIDERS,
];

@NgModule({
  declarations: [AppComponent],
  providers,
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RhCommonModule.forRoot(),
    Angulartics2Module.forRoot(),
    HubUiModule,
    AgGridModule,
    EffectsModule.forRoot([HubDefaultEffects]),
    EffectsModule.forRoot([MessagesEffects]),
    EffectsModule.forRoot([FiltersEffects]),
    EffectsModule.forRoot([SavedViewsEffects]),
    StoreModule.forRoot({
      [fromChannels.featureKey]: fromChannels.reducer,
      [fromMedia.featureKey]: fromMedia.reducer,
      [fromPartner.featureKey]: fromPartner.reducer,
      [fromPartnersList.featureKey]: fromPartnersList.reducer,
      [fromSession.featureKey]: fromSession.reducer,
      [fromHelp.featureKey]: fromHelp.reducer,
      [fromUser.featureKey]: fromUser.reducer,
      [fromUaConfig.featureKey]: fromUaConfig.reducer,
      [fromMessages.featureKey]: fromMessages.reducer,
      [fromFilters.featureKey]: fromFilters.reducer,
      [fromSavedViews.featureKey]: fromSavedViews.reducer,
      //PJ-TODO: Might have to register gap-analysis-tool filter reducer instead of localstore
    }, { metaReducers: [] }),
    !environment.production ? StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: true,
      name: 'Hub UI Store',
      connectInZone: true}) : [],
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(titleService: TitleService) {
    titleService.init();
    this.privateappendGaTrackingCode();
  }
  privateappendGaTrackingCode() {
    try {
      const script = document.createElement('script');
      script.innerHTML =
        `
        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', '` +
        environment.GA_TRACKING_ID +
        `', 'auto');
        ga('require', 'displayfeatures');
      `;
      document.head.appendChild(script);
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }
}
