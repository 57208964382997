import { MessageType } from '@hub-services/interfaces/message-type.enum';
import { Message } from '@hub-services/interfaces/message.model';
import { createReducer, on } from '@ngrx/store';
import * as MessagesActions from './messages.actions';
import { VisualStyle, VisualColor } from '@atpco/hub-ui';

export const featureKey = 'messages';

export interface HubMessagesState {
  messages: Message[];
  snackbar: {
    style: VisualStyle;
    color: VisualColor;
    message: string;
    action: string;
  };
}

export const initialState: HubMessagesState = {
  messages: [],
  snackbar: {
    style: null,
    color: null,
    message: null,
    action: null,
  },
};

export const reducer = createReducer(
  initialState,

  on(MessagesActions.createMessage, (state, action) => ({
    ...state,
    messages: [
      ...state.messages,
      action.message
    ]
  })),
  on(MessagesActions.createDefaultMessage, (state, action) => ({
    ...state,
    messages: [
      ...state.messages,
      { type: MessageType.ALERT, text: `We're sorry. Something went wrong.` }
    ]
  })),
  on(MessagesActions.createErrorMessage, (state, action) => ({
    ...state,
    messages: [
      ...state.messages,
      { type: MessageType.ALERT, text: action.errorText }
    ]
  })),
  on(MessagesActions.clearMessages, state => ({
    ...state,
    ...initialState,
  })),
  on(MessagesActions.setAppSnackbar, (state, action) => ({
    ...state,
    snackbar: {
      action: 'OK',
      style: VisualStyle.success,
      color: VisualColor.default,
      message: action.message,
    }
  })),
);
