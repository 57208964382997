import { catchError, map, share } from 'rxjs/operators';
import { Partner } from './partner';
import { Injectable } from '@angular/core';
import { ApiService } from './api';
import { Observable, of, forkJoin } from 'rxjs';
import { PartnerType } from '../admin/admin-partners/partner-type.enum';
import { MediaFileService } from './media-file.service';

@Injectable({ providedIn: 'root' })
export class PartnersService {
  path: string = '/api';
  partners: Partner[];

  constructor(
    private apiService: ApiService,
    private mediaFileService: MediaFileService,
  ) { }

  private observable: Observable<any>;

  getPartners(type: PartnerType) {
    return this.apiService.get(`${this.path}/v2/${type}s`).toPromise();
  }

  getPartnersV2Observable(type: PartnerType): Observable<any> {
    return this.apiService.get(`${this.path}/v2/${type}s`);
  }

  getChannelPartners() {
    return forkJoin([
      this.apiService.get(`${this.path}/distributors`).pipe(
        map((resp) => resp.map((partner) => this.addPartnerType(partner, 'distributors'))),
      ),
      this.apiService.get(`${this.path}/platforms`).pipe(
        map((resp) => resp.map((partner) => this.addPartnerType(partner, 'platforms'))),
      ),
    ]).toPromise();
  }

  private addPartnerType(partner: any, type: string) {
    partner['type'] = type;
    return partner;
  }

  getAllPartners() {
    if (this.partners) {
      return of(this.partners);
    } else if (this.observable) {
      return this.observable;
    } else {
      this.observable = this.apiService.get(`${this.path}/partners`).pipe(
        catchError(() => of([])),
        map((response: Partner[]) => {
          this.observable = null;
          this.partners = response;
          return this.partners;
        }),
        share(),
      );
      return this.observable;
    }
  }

  getPartner(type: PartnerType, id: number): Observable<Partner> {

    if (type === PartnerType.AIRLINE) {
      return this.apiService.get(`${this.path}/${type}s/${id}`);
    }
    return this.apiService.get(`${this.path}/v2/${type}s/${id}`);
  }

  private getPayloadAsFormData(type: PartnerType, partner: Partner, logoFile?: File): FormData {
    const payload = new FormData();

    const containerKey = type == PartnerType.AIRLINE ? 'data' : type; 

    for (let key in partner) {
      if (['terminals', 'subfleets', 'subscriptions', 'categories', 'brands'].includes(key)) {
        continue;
      }

      if(type == PartnerType.AIRLINE && key.includes('logo')) {
        continue;
      }

      if (key === 'id' && !partner[key]) {
        continue;
      }

      payload.append(`${containerKey}[${key}]`, partner[key]);
    }

    if (type !== PartnerType.AIRLINE) {
      if (logoFile) {
        payload.append(`${containerKey}[logo]`, logoFile, this.mediaFileService.sanitizeFileName(logoFile.name));
      }
    }

    return payload;
  }

  updatePartner(type: PartnerType, id: number, partner: Partner, logoFile?: File) {

    const url = type === PartnerType.AIRLINE 
      ? `${this.path}/${type}s/${id}`
      : `${this.path}/v2/${type}s/${id}`;

    const payload = this.getPayloadAsFormData(type, partner, logoFile);

    return this.apiService.putForm(url, payload).toPromise().then(() => partner);
  }

  addPartner(type: PartnerType, partner: Partner, logoFile?: File) {
    const url = type === PartnerType.AIRLINE 
      ? `${this.path}/${type}s`
      : `${this.path}/v2/${type}s`;
    const payload = this.getPayloadAsFormData(type, partner, logoFile);

    return this.apiService.postForm(url, payload).toPromise();
  }

  getPartnerUsers(type: PartnerType, id: number) {
    const url: string = `${this.path}/${type}s/${id}/users`;
    return this.apiService.get(url);
  }

  updateUserPassword(type: PartnerType, id: number, user: any, password: string) {
    const url: string = `${this.path}/${type}s/${id}/users/${user.id}`;
    return this.apiService.put(url, { data: { password: password } }).toPromise();
  }

  createUser(type: PartnerType, id: number, user: any) {
    const url: string = `${this.path}/${type}s/${id}/users`;
    return this.apiService
      .post(url, {
        data: {
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          password: user.password,
          role: user.role,
        },
      })
      .toPromise();
  }

  deleteUser(type: PartnerType, id: number, user: any) {
    const url: string = `${this.path}/${type}s/${id}/users/${user.id}`;
    return this.apiService.delete(url).toPromise();
  }
}
