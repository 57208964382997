import { Partner } from '@hub-services/partner';
import { Subfleet } from '@hub-services/subfleets.service';
import { createReducer, on } from '@ngrx/store';
import * as PartnerActions from './selected-partner.actions';

export const featureKey = 'selectedPartner';

export interface HubPartnerState {
  partner: Partner;
  subfleets: Subfleet[];
  users: any[];
  admins: any[]
}

export const initialState: HubPartnerState = {
  partner: null,
  subfleets: null,
  users: null,
  admins: null,
};

export const reducer = createReducer(
  initialState,

  on(PartnerActions.setPartner, (state, action) => ({
    ...state,
    partner: action.partner,
  })),
  on(PartnerActions.setUsersAndAdmins, (state, action) => ({
    ...state,
    users: action.users,
    admins: action.admins,
  })),
  on(PartnerActions.setSubfeeets, (state, action) => ({
    ...state,
    subfleets: action.subfleets,
  })),
  on(PartnerActions.createUserInStore, (state, action) => ({
    ...state,
    users: state.users?.length ? [...state.users, action.user] : [action.user],
  })),
  on(PartnerActions.updateUserInStore, (state, action) => {

    const userIdx = state.users.findIndex(user => user.id === action.user.id);

    const users = [...state.users];
    users[userIdx] = { ...action.user };

    return {
      ...state,
      users,
    };
  }),
  on(PartnerActions.deleteUserFromStore, (state, action) => ({
    ...state,
    users: state.users.filter(user => user.id !== action.id),
  })),

  on(PartnerActions.addCategoryToStore, (state, action) => ({
    ...state,
    partner: {
      ...state.partner,
      categories: [
        ...state.partner.categories,
        { id: action.id, name: action.name },
      ]
    },
  })),

  on(PartnerActions.setPartnerBrandsInStore, (state, action) => ({
    ...state,
    partner: {
      ...state.partner,
      brands: [
        ...action.brands,
      ]
    },
  })),

  on(PartnerActions.resetPartnerState, state => ({
    ...state,
    ...initialState,
  })),

);

