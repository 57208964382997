import { User } from '@hub-services/user.service';
import { createAction, props } from '@ngrx/store';
import { HubSessionState } from './session.reducer';

export const setSessionData = createAction(
  '[Session] Set session data',
  props<{ data: HubSessionState }>()
);

export const updateSessionUserInStore = createAction(
  '[Session] Update session user in store',
  props<{ user: Partial<User> }>()
);
