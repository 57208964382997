import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'asLuxonDate'
})
export class AsLuxonDatePipe implements PipeTransform {

  transform(value: string): DateTime {
    if (!value?.length) {
      return null;
    }
    
    return DateTime.fromFormat(value, 'yyyy-MM-dd');
  }
}
